import { render, staticRenderFns } from "./QuerySettings.vue?vue&type=template&id=61bbd08b&scoped=true"
import script from "./QuerySettings.vue?vue&type=script&setup=true&lang=js"
export * from "./QuerySettings.vue?vue&type=script&setup=true&lang=js"
import style0 from "./QuerySettings.vue?vue&type=style&index=0&id=61bbd08b&prod&scoped=true&lang=stylus"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61bbd08b",
  null
  
)

export default component.exports